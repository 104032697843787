@media only screen and (min-width: 600px) {
  .mainTab {
    max-width: 600px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
  }
}

a {
  color: #000
}

.label, a {
  font-weight: 700
}

.label {
  display: block;
  margin-bottom: 7px;
  text-align: center;
  width: 100%
}

.radioGroup {
  background: #ececec;
  border-radius: 25px;
  display: flex;
  margin: 0 auto 30px;
  padding: 0;
  width: 85%
}

@media only screen and (min-width: 600px) {
  .radioGroup {
    width: 180px;
    margin-bottom: 10px;
  }

  .options {
    display: flex;
  }
}

.radioGroup.small {
  background: none;
  width: 40%
}

.radioGroup.clear {
  background: none
}

.radioGroup input[type=button] {
  display: inline-block;
  font-size: 11px;
  margin: 3px
}

.radioGroup input[type=button].active {
  background: #2db563;
  border-color: #2db563;
  color: #fff
}

.radioGroup input[type=button].inactive {
  background: none;
  border: none;
  color: #000
}

#setting-buttons {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

#setting-buttons #start-btn {
  margin-right: 2%;
  margin-left: 2%;
  width: 50%;
}

#setting-buttons #tutorial-btn {
  margin-right: 2%;
  margin-left: 2%;
  width: 30%;
}