.card-table {
  margin-left: 2%;
  margin-right: 2%;
  width: 98%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
}

.card-cell {
  max-width: 300px;
  margin-top: 1%;
  margin-left: 1%;
  margin-right: 1%;
  position: relative;
}

.card-cell .card-label {
  position: absolute;
  left: 5%;
  top: 5%;
  width: 35px;
  height: 35px;
  text-align: center;
  font-weight: bolder;
  font-size: x-large;
  background-color: rgb(248, 248, 248);
  border-radius: 50%;
}

.card-cell img {
  max-width: 100%;
  height: auto;
}

.card-table .card-ex {
  max-width: 47%;
  min-width: 800px;
  margin: 0 0.5% 10px 0.5%;
  display: flex;
  align-items: center;
  text-align: center;

  border: 1px solid rgba(0, 0, 0, 0.075);
  border-bottom-color: rgba(0, 0, 0, 0.125);
  border-radius: 4px;
  box-shadow: 0 1px 2px rgb(0 0 0 / 5%), 0 5px 10px rgb(0 0 0 / 5%);
  transition: border-color 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
}

.card-table .card-ex .card-ex-label {
  font-size: 25px;
  width: 10%;
}

.card-table .card-ex .card-ex-label:hover {
  cursor: pointer;
}

.card-table .card-ex .card-ex-cards {
  max-width: 90%;
  display: flex;
}

.card-table .card-ex .card-cell {
  max-width: 50%;
  margin: 1% 2% 1% 2%;
}

@media only screen and (max-width: 600px) {
  .card-table .card-ex {
    max-width: 400px;
    min-width: 0;
    position: relative;
  }

  .card-table .card-ex .card-ex-label {
    width: 15%;
    font-size: 20px;
  }

  .card-table .card-ex .card-ex-cards {
    width: 85%;
    display: block;
  }

  .card-table .card-ex .card-cell {
    max-width: 100%;
  }

}

.card-nm-labels {
  width: 98%;
  margin: 20px 2% 0 2%;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.card-nm-labels .card-label {
  font-size: 27px;
  width: 23%;
  max-width: 300px;
  min-height: 100px;
  margin-left: 1%;
  margin-right: 1%;
  display: flex;
  justify-content: center;
  align-items: center;

  border: 1px solid rgba(0, 0, 0, 0.075);
  border-bottom-color: rgba(0, 0, 0, 0.125);
  border-radius: 6px;
  box-shadow: 0 1px 2px rgb(0 0 0 / 5%), 0 5px 10px rgb(0 0 0 / 5%);
  transition: border-color 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
}

.card-nm-labels .card-label:hover {
  cursor: pointer;
}

.tip {
  margin-top: 5px;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tip input[type=button] {
  width: 80px;
  margin: 0 0 0 10px;
}

.player-area {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 95%;
}