.codeInserter {
  min-width: 220px;
  margin-left: 30px;
  margin-right: 30px;
}

.codeDisplayer {
  height: 40px;
}

.pageInputCode .codeDisplayer.code .digit {
  display: inline-block;
  text-align: center;
  width: 50px;
  font-size: 30px;
}

.codeInserter {
  margin-bottom: 15px;
  display: inline-block;
}

.codeMessage {
  margin-bottom: 15px;
}

.wrongCode {
  color: #d01B1b;
  margin-bottom: 5px;
}

.correctCode {
  color: #2db563;
}

span.blueTriangle,
span.yellowSquare,
span.purpleCircle {
  width: 60px;
  height: 60px;
  line-height: 60px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  margin: 5px;
  background-position: center center;
  background-repeat: no-repeat;
}

span.blueTriangle {
  background-image: url("../public/icons/ico_bluetriangle.svg");
}

span.yellowSquare {
  background-image: url("../public/icons/ico_yellowsquare.svg");
}

span.purpleCircle {
  background-image: url("../public/icons/ico_purplecircle.svg");
}

.codeInserter .submit {
  background: #2db563;
  border-color: #2db563;
  color: #fff;
}