.App {
  text-align: center;
}

#root {
  position: absolute;
  top: 0;
  left: 0;
  width: 98%;
  height: 100vh;
}

@media only screen and (min-width: 600px) {
  #root {
    position: relative;
    margin-left: 2%;
    margin-right: 2%;
  }
}

.button, input[type=button] {
  background: none;
  border: 2px solid #000;
  border-radius: 20px;
  color: #000;
  display: block;
  font-weight: bolder;
  height: 30px;
  line-height: 20px;
  margin: 10px auto;
  padding: 0 10px;
  text-align: center;
  text-transform: uppercase;
  width: 85%;
}

.button,
input[type=button]:hover {
  cursor: pointer;
}

.button.fit {
  width: fit-content;
}

/* colored span of texts */
span.green {
  background: #2db563;
  border-radius: 5px;
  padding: 5px;
  color: #ffffff;
}

/* shaped span for numbers */
span.square {
  width: 50px;
  height: 50px;
  line-height: 40px;
  text-align: center;
  display: inline-block;
  border-radius: 10px;
  border: 2px solid transparent;
  margin: 5px;
}

span.bigSquare {

  width: 60px;
  height: 60px;
  line-height: 60px;
  font-size: 27px;
  font-weight: bold;
  border-radius: 10px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  background: #ececec;
  color: #000000;
  margin: 5px;
}