.note-sheet {
  width: calc(100% - 400px);
  min-width: 550px;
}

.note-sheet .row {
  padding: 6px;
  text-align: center;
  display: flex;
  justify-content: space-between;
}

.note-sheet .row.head {
  font-weight: bold;
  line-height: 60px;
}

.note-sheet .row .code {
  display: inline-block;
  font-size: 25px;
  min-width: 160px;
}

.note-sheet .row .code .bcode,
.note-sheet .row .code .ycode,
.note-sheet .row .code .pcode {
  font-weight: bolder;
  display: inline-block;
  width: 50px;
}

.note-sheet .row .code .bcode {
  color: #58b3da
}

.note-sheet .row .code .ycode {
  color: #febc12
}

.note-sheet .row .code .pcode {
  color: #7f66ad
}

.note-sheet .row .cell {
  display: inline-block;
  font-size: 25px;
  min-width: 35px;
}

.note-sheet .row .cell.info {
  width: calc(100% - 200px);
  padding-left: 40px;
  text-align: left;
}

.note-sheet .row .cell.yes {
  color: green;
}

.note-sheet .row .cell.no {
  color: red;
}

.note-sheet span.blueTriangle,
.note-sheet span.yellowSquare,
.note-sheet span.purpleCircle {
  width: 50px;
  height: 50px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  margin: 1px;
  background-position: center center;
  background-repeat: no-repeat;
}

@media only screen and (max-width: 600px) {
  .note-sheet {
    width: 95%;
    min-width: 0;
  }

  .note-sheet .row {
    justify-content: space-around;
  }

  .note-sheet .row .code {
    display: inline-block;
    font-size: 20px;
  }

  .note-sheet .row .cell {
    display: inline-block;
    font-size: 20px;
  }

  .note-sheet .row .code .bcode,
  .note-sheet .row .code .ycode,
  .note-sheet .row .code .pcode {
    font-weight: bolder;
    display: inline-block;
    width: 31px;
  }

  .note-sheet span.blueTriangle,
  .note-sheet span.yellowSquare,
  .note-sheet span.purpleCircle {
    width: 25px;
    height: 25px;
    background-size: contain;
    margin: 3px;
  }
}