#tipBlock {
  background-color: rgb(234, 247, 239);
  color: rgb(50, 50, 50);
  padding: 15px 15px 15px 15px;
  border: 1px solid rgb(45, 181, 99);
  border-radius: 10px;
}

#operationTip {
  font-style: italic;
}